<template>
    <div class="pricing-box">
        <div class="w3l-bootstrap-header fixed-top">
  
  <nav class="navbar navbar-expand-lg navbar-light p-2">
    <div class="container">
      <a class="navbar-brand" href="/">Futong</a>
      <!-- if logo is image enable this   
    <a class="navbar-brand" href="#index.html">
        <img src="image-path" alt="Your logo" title="Your logo" style="height:35px;" />
    </a> -->
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <a class="nav-link" href="/">{{ $t('Contact.Nav.Home') }}</a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" href="/#/pricing">Pricing</a>
            <!-- <a class="nav-link" href="../../public/pricing.html">Pricing</a> -->
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#/contact">{{ $t('Contact.Nav.Contact') }}</a>
            <!-- <router-link tag="a" class="nav-link" to="/contact">{{ $t('Contact.Nav.Contact') }}</router-link> -->
          </li>
        </ul>
      </div>
      <!-- <div id="fy" style="cursor: pointer;color:rgba(25, 51, 101, 0.5)" @click="changeLang">{{ locales }}</div> -->
    </div>
  </nav>
</div>
<!-- contacts -->
<section class="w3l-contact mt-5">
  <div class="contacts-9 py-5 mt-5">
    <div class="container py-lg-3">
      <div class="row top-map" style="justify-content:center">
        
        <div class="map-content-9 col-md-7 mt-5 mt-md-0">
          <!-- https://sendmail.w3layouts.com/submitForm -->
          <div class="box">
              <div class="visi" style="background:#F8B12E;padding:10px">
                Please fill up the following form of promotion details of your product, our support team will follow up and contact you for further steps to start the promotion
            </div>
            <!-- :action="`https://futong.online/send_cloud.class.php?to=${email}`" method="post" -->
            <!-- action="https://futong.online/send_cloud.class.php" method="post" -->
        <form @submit="initClick($event)">
            <div class="item">
                <label for="">Patform</label>
                <br />
                <input style="-webkit-appearance: checkbox;width:22px;height:20px;vertical-align:-4px" type="checkbox" name="Facebook">Facebook
                <input style="-webkit-appearance: checkbox;width:22px;height:20px;vertical-align:-4px" type="checkbox" name="Tiktok">Tiktok
                <input style="-webkit-appearance: checkbox;width:22px;height:20px;vertical-align:-4px" type="checkbox" name="Google">Google
            </div>

            <div class="item">
                <label for="">Series</label>
                <br />
                <input class="inp" type="text" name="Series" />
            </div>
            <div class="item">
                <label>Product Introduction</label>
                <br />
                <textarea maxLength="200" name="ProductIntroduction" id="" cols="66" rows="10"></textarea>
            </div>
            <div class="item">
                <label for="">Product Materials(URL)</label>
                <br />
                <input class="inp" type="text" name="ProductMaterials" />
            </div>
            <div class="item">
                <label for="">Product Type</label>
                <br />
                <input id="picture" style="-webkit-appearance: checkbox;width:22px;height:20px;vertical-align:-4px" type="radio" name="ProductType">picture
                <input id="video" style="-webkit-appearance: checkbox;width:22px;height:20px;vertical-align:-4px" type="radio" name="ProductType">video
            </div>
            <div class="item">
                <label for="">Product Link(optional)</label>
                <br />
                <input class="inp" type="text" name="ProductLink" />
            </div>
            <!-- <div class="item">
                <label for="">Expected Cost in USD</label>
                <br />
                <input class="inp" min="0" type="number" name="ProductLink" />
            </div> -->
            <div class="item">
                <label for="">Expected Day(s) of Promotion</label>
                <br />
                <input onkeyup="this.value=this.value.replace(/\D|^0/g,'')" class="inp" min="0" name="Promotion" />
            </div>
            <div class="item">
                <label for="">Expected Start Date</label>
                <br />
                <input class="inp" min="0" type="date" name="Date" />
            </div>
            <div class="item">
                <label for="">Your Email</label>
                <br />
                <input v-model="obj.email" class="inp" min="0" type="email" name="email" />
            </div>
            <div class="item">
                <label for="">Phone Number</label>
                <br />
                <input class="inp" min="0" type="tel" name="PhoneNumber" />
            </div>
            <div v-if="visi" class="visi">
                Check out successfully,  please check your email for details,  our support team will contact you soon for further steps
            </div>
            <!-- <button type="submit">Check <button type="submit">Check Out</button>Out</button> -->
            <!-- <button @click="initClick">Check Out</button> -->
            <!-- <el-button @click="initClick" :loading="lo">Check Out</el-button> -->
        </form>
        <!--  -->

        <div id="smart-button-container">
          <div>
            <label for="description">Check Out Ref. </label>
            <br />
            <input style="width:100%" type="text" name="descriptionInput" ref="description" id="description" maxlength="127" value="">
            </div>
            <p id="descriptionError" style="visibility: hidden; color:red; text-align: center;">Please enter a description</p>
          <div>
            <label for="amount">Expected Cost </label>
            <br />
            <input style="width: 90%" name="amountInput" type="number" id="amount" value="" ><span> USD</span>
            </div>
            <p id="priceLabelError" style="visibility: hidden; color:red; text-align: center;">Please enter a price</p>
          <div id="invoiceidDiv" style="text-align: center; display: none;"><label for="invoiceid"> </label><input name="invoiceid" maxlength="127" type="text" id="invoiceid" value="" ></div>
            <p id="invoiceidError" style="visibility: hidden; color:red; text-align: center;">Please enter an Invoice ID</p>
          <div style="text-align: center; margin-top: 0.625rem;" id="paypal-button-container"></div>
        </div>

        <!--  -->
        </div>
        </div>
      </div>
    </div>
  </div>
  <!--div class="map">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387193.3059445135!2d-74.25986613799748!3d40.69714941774136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m3!3e3!4m0!4m0!5e0!3m2!1sen!2sin!4v1570181661801!5m2!1sen!2sin"
      allowfullscreen=""></iframe>
  </div-->
</section>
<!-- //contacts -->
      <!-- footer-28 block -->
      <section class="w3l-market-footer">
        <footer class="footer-28">
          <div class="footer-bg-layer">
            <div class="container py-lg-3">
              <div class="row footer-top-28">
                <div class="col-md-6 footer-list-28 mt-5">
                  <h6 class="footer-title-28">{{ $t('Contact.Footer.ContactInformation') }}</h6>
                  <ul>
                    <li>
                      <p><strong>{{ $t('Contact.Footer.Address') }}</strong> : {{ $t('Contact.Footer.AddressValue') }}</p>
                    </li>
                    <li>
                      <p><strong>{{ $t('Contact.Footer.Email') }}</strong> : <a href="mailto:info@futong.online">info@futong.online</a></p>
                    </li>
                  </ul>
                </div>
                <!--div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4 footer-list-28 mt-5">
                      <h6 class="footer-title-28">Company</h6>
                      <ul>
                        <li><a href="about.html">About</a></li>
                        <li><a href="#blog.html">Blog Posts</a></li>
                        <li><a href="#pricing.html">FAQ</a></li>
                        <li><a href="#pricing.html">Pricing</a></li>
                      </ul>
                    </div>
                    <div class="col-md-4 footer-list-28 mt-5">
                      <h6 class="footer-title-28">Support</h6>
                      <ul>
                        <li><a href="contact.html">Contact Us</a></li>
                        <li><a href="#signup.html">Create account</a></li>
                        <li><a href="#learn.html">Learning Center</a></li>
                        <li><a href="#career.html">Team</a></li>
                      </ul>
                    </div>
                    <div class="col-md-4 footer-list-28 mt-5">
                      <h6 class="footer-title-28">Product</h6>
                      <ul>
                        <li><a href="#URL">Market</a></li>
                        <li><a href="#URL">VIP</a></li>
                        <li><a href="#URL">Resources</a></li>
                        <li><a href="#URL">Sale</a></li>
                        <li><a href="#URL">Admin UI</a></li>
                      </ul>
                    </div>
                  </div>
                </div-->
              </div>
            </div>


            <div class="midd-footer-28 align-center py-lg-4 py-3 mt-5">
              <div class="container">
                <p class="copy-footer-28 text-center"> &copy; 2022 FU TONG E-COMMERCE SERVICES LIMITED. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </footer>

        <!-- madal -->
          <div class="ld-hide">
            <div class="loading-box" id="loading">
               <div class="container2 loading1">
                  <div class="shape shape1"></div>
                  <div class="shape shape2"></div>
                  <div class="shape shape3"></div>
                  <div class="shape shape4"></div>
                </div>
            </div>
          </div>

          <!-- toast -->
          <div class="toast-container position-fixed bottom-0 end-0 p-3">
            <div id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
              <div class="toast-header">
                <!-- <img src="..." class="rounded me-2" alt="..."> -->
                <strong class="me-auto">Bootstrap</strong>
                <small>11 mins ago</small>
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
              </div>
              <div class="toast-body">
                Hello, world! This is a toast message.
              </div>
            </div>
          </div>

          

        <!-- move top -->
        <!-- <button @click="topFunction()" id="movetop" title="Go to top">
          &#10548;
        </button> -->
      </section>


        
    </div>
</template>

<script src="https://www.paypal.com/sdk/js?client-id=sb&enable-funding=venmo&currency=USD" data-sdk-integration-source="button-factory"></script>

<script>
// import paypal from 'https://www.paypal.com/sdk/js?client-id=sb&enable-funding=venmo&currency=USD'
// import { Message } from 'element-ui';
// import { Loading } from 'element-ui';
// function initPayPalButton() {
//     var description = document.querySelector('#smart-button-container #description');
//     var amount = document.querySelector('#smart-button-container #amount');
//     var descriptionError = document.querySelector('#smart-button-container #descriptionError');
//     var priceError = document.querySelector('#smart-button-container #priceLabelError');
//     var invoiceid = document.querySelector('#smart-button-container #invoiceid');
//     var invoiceidError = document.querySelector('#smart-button-container #invoiceidError');
//     var invoiceidDiv = document.querySelector('#smart-button-container #invoiceidDiv');

//     var elArr = [description, amount];
//     console.log('invoiceidDiv', invoiceidDiv)

//     // if (invoiceidDiv.firstChild.innerHTML.length > 1) {
//     //   invoiceidDiv.style.display = "block";
//     // }

//     var purchase_units = [];
//     purchase_units[0] = {};
//     purchase_units[0].amount = {};

//     function validate(event) {
//       return event.value.length > 0;
//     }

//     paypal.Buttons({
//       style: {
//         color: 'blue',
//         shape: 'pill',
//         label: 'paypal',
//         layout: 'vertical',
        
//       },

//       onInit: function (data, actions) {
//         actions.disable();

//         if(invoiceidDiv.style.display === "block") {
//           elArr.push(invoiceid);
//         }

//         elArr.forEach(function (item) {
//           item.addEventListener('keyup', function (event) {
//             var result = elArr.every(validate);
//             if (result) {
//               actions.enable();
//             } else {
//               actions.disable();
//             }
//           });
//         });
//       },

//       onClick: function () {
//         console.log('description', this.$ref)
//         if (description.value.length < 1) {
//           descriptionError.style.visibility = "visible";
//         } else {
//           descriptionError.style.visibility = "hidden";
//         }

//         if (amount.value.length < 1) {
//           priceError.style.visibility = "visible";
//         } else {
//           priceError.style.visibility = "hidden";
//         }

//         if (invoiceid.value.length < 1 && invoiceidDiv.style.display === "block") {
//           invoiceidError.style.visibility = "visible";
//         } else {
//           invoiceidError.style.visibility = "hidden";
//         }

//         purchase_units[0].description = description.value;
//         purchase_units[0].amount.value = amount.value;

//         if(invoiceid.value !== '') {
//           purchase_units[0].invoice_id = invoiceid.value;
//         }
//       },

//       createOrder: function (data, actions) {
//         return actions.order.create({
//           purchase_units: purchase_units,
//         });
//       },

//       onApprove: function (data, actions) {
//         return actions.order.capture().then(function (orderData) {

//           // Full available details
//           console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));

//           // Show a success message within this page, e.g.
//           const element = document.getElementById('paypal-button-container');
//           element.innerHTML = '';
//           element.innerHTML = '<h3>Thank you for your payment!</h3>';

//           // Or go to another URL:  actions.redirect('thank_you.html');
          
//         });
//       },

//       onError: function (err) {
//         console.log(err);
//       }
//     }).render('#paypal-button-container');
//   }
    // initPayPalButton();
export default {
    data() {
        return {
            obj: {
                email: ""
            },
            visi: false,
            lo: false
        }
    },
    mounted() {
      this.initPayPalButton()
      console.log('description2222==>', this.$refs)

    },
    created() {
      // this.initPayPalButton()
    },
    methods: {
    clickH() {
      let description = this.$refs.description
      var amount = document.querySelector('#smart-button-container #amount');
    var descriptionError = document.querySelector('#smart-button-container #descriptionError');
    var priceError = document.querySelector('#smart-button-container #priceLabelError');
    var invoiceid = document.querySelector('#smart-button-container #invoiceid');
    var invoiceidError = document.querySelector('#smart-button-container #invoiceidError');
    var invoiceidDiv = document.querySelector('#smart-button-container #invoiceidDiv');
      if (description.value.length < 1) {
          descriptionError.style.visibility = "visible";
        } else {
          descriptionError.style.visibility = "hidden";
        }

        if (amount.value.length < 1) {
          priceError.style.visibility = "visible";
        } else {
          priceError.style.visibility = "hidden";
        }

        if (invoiceid.value.length < 1 && invoiceidDiv.style.display === "block") {
          invoiceidError.style.visibility = "visible";
        } else {
          invoiceidError.style.visibility = "hidden";
        }

        purchase_units[0].description = description.value;
        purchase_units[0].amount.value = amount.value;

        if(invoiceid.value !== '') {
          purchase_units[0].invoice_id = invoiceid.value;
        }
    },
    initPayPalButton() {
    // var description = document.querySelector('#smart-button-container #description');
    let description = this.$refs.description
    var amount = document.querySelector('#smart-button-container #amount');
    var descriptionError = document.querySelector('#smart-button-container #descriptionError');
    var priceError = document.querySelector('#smart-button-container #priceLabelError');
    var invoiceid = document.querySelector('#smart-button-container #invoiceid');
    var invoiceidError = document.querySelector('#smart-button-container #invoiceidError');
    var invoiceidDiv = document.querySelector('#smart-button-container #invoiceidDiv');

    var elArr = [description, amount];
    console.log('invoiceidDiv', invoiceidDiv)
    console.log('description==>', description)

    // if (invoiceidDiv.firstChild.innerHTML.lengtsh > 1) {
    //   invoiceidDiv.style.display = "block";
    // }

    var purchase_units = [];
    purchase_units[0] = {};
    purchase_units[0].amount = {};

    function validate(event) {
      return event.value.length > 0;
    }

    paypal.Buttons({
      style: {
        color: 'blue',
        shape: 'pill',
        label: 'paypal',
        layout: 'vertical',
        
      },

      onInit: function (data, actions) {
        actions.disable();

        if(invoiceidDiv.style.display === "block") {
          elArr.push(invoiceid);
        }

        elArr.forEach(function (item) {
          item.addEventListener('keyup', function (event) {
            var result = elArr.every(validate);
            if (result) {
              actions.enable();
            } else {
              actions.disable();
            }
          });
        });
      },
      
      onClick: function () {
          // this.clickH()
        console.log('description', this.$refs)
        
        if (description.value.length < 1) {
          descriptionError.style.visibility = "visible";
        } else {
          descriptionError.style.visibility = "hidden";
        }

        if (amount.value.length < 1) {
          priceError.style.visibility = "visible";
        } else {
          priceError.style.visibility = "hidden";
        }

        if (invoiceid.value.length < 1 && invoiceidDiv.style.display === "block") {
          invoiceidError.style.visibility = "visible";
        } else {
          invoiceidError.style.visibility = "hidden";
        }

        purchase_units[0].description = description.value;
        purchase_units[0].amount.value = amount.value;

        if(invoiceid.value !== '') {
          purchase_units[0].invoice_id = invoiceid.value;
        }
      },

      createOrder: function (data, actions) {
        return actions.order.create({
          purchase_units: purchase_units,
        });
      },

      onApprove: function (data, actions) {
        return actions.order.capture().then(function (orderData) {

          // Full available details
          console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));

          // Show a success message within this page, e.g.
          const element = document.getElementById('paypal-button-container');
          element.innerHTML = '';
          element.innerHTML = '<h3>Thank you for your payment!</h3>';

          // Or go to another URL:  actions.redirect('thank_you.html');
          
        });
      },

      onError: function (err) {
        console.log(err);
      }
    }).render('#paypal-button-container');
  },
        initClick(e) {
            this.lo = true
            e.preventDefault()
            // let loading = Loading.service({ fullscreen: true })
            let data = {
                Series: "",
                ProductIntroduction: "",
                ProductMaterials: "",
                ProductLink: '',
                Promotion: '',
                Date: '',
                PhoneNumber: '',
                to: this.obj.email
            }
            let formData = new FormData();
            for (let it in data) {
            formData.append(it, data[it]);
            }
            
            this.$axios({
                method: "POST",
                url: "/send_cloud.class.php",
                data: formData,
            }).then((res) => {
                console.log('abc==>', res)
                this.lo = false
                if(res.data.status == 200) {
                    this.visi = true
                    // loading.close()
                }else {
                     this.visi = true
                    //  loading.close()
                }
                
            });
        }
    }
}
</script>

<style scoped>
.visi{
    padding: 3px 3px;
    background: #4cec84;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 10px;
}
.box{
            width: 500px;
            margin: 20px auto;
        }
        .item{
            margin-bottom: 10px;
        }
        input{
            /* width: 100%; */
            padding: 10px 2px;
            outline: 0;
            border: 0;
            border:1px solid #ccc;
            border-radius: 5px;
        }
        .inp{
            width: 100%;
        }
        label{
            margin-bottom: 5px;
            color: #999;
        }
        button{
            border: 0;
            outline: 0;
            padding: 10px 10px;
            border-radius: 5px;
            cursor: pointer;
            color: #fff;
            background: #8812FA;
        }
</style>

