/*
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2022-07-18 11:11:08
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-12-13 17:55:40
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import $ from 'jquery'
import store from './store'
import "../src/common/index.css"
import VueI18n from 'vue-i18n'
import en from "./utils/lang/en"
import tw from "./utils/lang/tw"
import axios from "axios"
import Element from 'element-ui'
Vue.config.productionTip = false
Vue.use(VueI18n)
Vue.prototype.$axios = axios
Vue.use(Element)
const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'en', // 语言标识
  messages: {
    en: en,
    tw: tw
  }
})

new Vue({
  router,
  $,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
