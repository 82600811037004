<template>
    <div>
        <div class="w3l-bootstrap-header fixed-top">
  
  <nav class="navbar navbar-expand-lg navbar-light p-2">
    <div class="container">
      <a class="navbar-brand" href="/">Futong</a>
      <!-- if logo is image enable this   
    <a class="navbar-brand" href="#index.html">
        <img src="image-path" alt="Your logo" title="Your logo" style="height:35px;" />
    </a> -->
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <a class="nav-link" href="/">{{ $t('Contact.Nav.Home') }}</a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" href="/#/pricing">Pricing</a>
            <!-- <a class="nav-link" href="../../public/pricing.html">Pricing</a> -->
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#/contact">{{ $t('Contact.Nav.Contact') }}</a>
            <!-- <router-link tag="a" class="nav-link" to="/contact">{{ $t('Contact.Nav.Contact') }}</router-link> -->
          </li>
        </ul>
      </div>
      <div id="fy" style="cursor: pointer;color:rgba(25, 51, 101, 0.5)" @click="changeLang">{{ locales }}</div>
    </div>
  </nav>
</div>
<!-- contacts -->
<section class="w3l-contact mt-5">
  <div class="contacts-9 py-5 mt-5">
    <div class="container py-lg-3">
      <div class="row top-map">
        <div class="cont-details col-md-5">
          <div class="heading mb-lg-4 mb-4">
            <h3 class="head">{{ $t('Contact.ContactUs') }} </h3>
          </div>
          <div class="cont-top">
            <!--div class="cont-left">
              <span class="fa fa-phone"></span>
            </div>
            <div class="cont-right">
              <p><a href="tel:+44-99-555-42">+44-99-555-42</a></p>

            </div-->
          </div>
          <div class="cont-top mt-4">
            <div class="cont-left">
              <span class="fa fa-envelope-o"></span>
            </div>
            <div class="cont-right">
              <p><a href="mailto:info@futong.online" class="mail">info@futong.online</a></p>
            </div>
          </div>
          <div class="cont-top mt-4">
            <div class="cont-left">
              <span class="fa fa-map-marker"></span>
            </div>
            <div class="cont-right">
              <p>{{ $t('Contact.AddressValue') }}</p>
            </div>
          </div>
        </div>
        <div class="map-content-9 col-md-7 mt-5 mt-md-0">
          <!-- https://sendmail.w3layouts.com/submitForm -->
          <form action="" method="post">
            <div class="form-group row">
              <div class="col-md-6">
                <label class="contact-textfield-label" for="w3lName">{{ $t('Contact.Form.FirstName') }}</label>
                <input type="text" class="form-control" name="w3lName" id="w3lName" placeholder="">
              </div>
              <div class="col-md-6 mt-md-0 mt-3">
                <label class="contact-textfield-label" for="w3lName">{{ $t('Contact.Form.LastName') }}</label>
                <input type="text" class="form-control" name="w3lName" placeholder="">
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label class="contact-textfield-label" for="w3lSender">{{ $t('Contact.Form.YourEmailID') }}</label>
                <!-- required="" -->
                <input type="email" class="form-control" name="w3lSender" id="w3lSender" placeholder="">
              </div>
              <div class="col-md-6 mt-md-0 mt-3">
                <label class="contact-textfield-label" for="w3lPhone">{{ $t('Contact.Form.PhoneNumber') }}</label>
                <input type="tel" class="form-control" name="w3lPhone" id="w3lPhone" placeholder="">
              </div>
            </div>
            <div class="form-group">
              <label class="contact-textfield-label" for="w3lSubject">{{ $t('Contact.Form.Subject') }}</label>
              <input type="text" class="form-control" name="w3lSubject" id="w3lSubject" placeholder="">
            </div>
            <div class="form-group">
              <label class="contact-textfield-label" for="w3lMessage">{{ $t('Contact.Form.Message') }}</label>
              <textarea name="w3lMessage" class="form-control" id="w3lMessage" placeholder=""></textarea>
            </div>
            <!-- <button type="submit" class="btn btn-primary btn-contact">Send Message</button> -->
            <div class="alert alert-success" style="display: none;" id="Myalert" data-dismiss="alert" role="alert">
              <strong>Success</strong>
              <!-- <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button> -->
            </div>
            <button @click="clickSend" type="button" class="btn btn-primary btn-contact" id="sendBtn">{{ $t('Contact.Form.SendMessage') }}</button>
            
          </form>
        </div>
      </div>
    </div>
  </div>
  <!--div class="map">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387193.3059445135!2d-74.25986613799748!3d40.69714941774136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m3!3e3!4m0!4m0!5e0!3m2!1sen!2sin!4v1570181661801!5m2!1sen!2sin"
      allowfullscreen=""></iframe>
  </div-->
</section>
<!-- //contacts -->
      <!-- footer-28 block -->
      <section class="w3l-market-footer">
        <footer class="footer-28">
          <div class="footer-bg-layer">
            <div class="container py-lg-3">
              <div class="row footer-top-28">
                <div class="col-md-6 footer-list-28 mt-5">
                  <h6 class="footer-title-28">{{ $t('Contact.Footer.ContactInformation') }}</h6>
                  <ul>
                    <li>
                      <p><strong>{{ $t('Contact.Footer.Address') }}</strong> : {{ $t('Contact.Footer.AddressValue') }}</p>
                    </li>
                    <li>
                      <p><strong>{{ $t('Contact.Footer.Email') }}</strong> : <a href="mailto:info@futong.online">info@futong.online</a></p>
                    </li>
                  </ul>
                </div>
                <!--div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4 footer-list-28 mt-5">
                      <h6 class="footer-title-28">Company</h6>
                      <ul>
                        <li><a href="about.html">About</a></li>
                        <li><a href="#blog.html">Blog Posts</a></li>
                        <li><a href="#pricing.html">FAQ</a></li>
                        <li><a href="#pricing.html">Pricing</a></li>
                      </ul>
                    </div>
                    <div class="col-md-4 footer-list-28 mt-5">
                      <h6 class="footer-title-28">Support</h6>
                      <ul>
                        <li><a href="contact.html">Contact Us</a></li>
                        <li><a href="#signup.html">Create account</a></li>
                        <li><a href="#learn.html">Learning Center</a></li>
                        <li><a href="#career.html">Team</a></li>
                      </ul>
                    </div>
                    <div class="col-md-4 footer-list-28 mt-5">
                      <h6 class="footer-title-28">Product</h6>
                      <ul>
                        <li><a href="#URL">Market</a></li>
                        <li><a href="#URL">VIP</a></li>
                        <li><a href="#URL">Resources</a></li>
                        <li><a href="#URL">Sale</a></li>
                        <li><a href="#URL">Admin UI</a></li>
                      </ul>
                    </div>
                  </div>
                </div-->
              </div>
            </div>


            <div class="midd-footer-28 align-center py-lg-4 py-3 mt-5">
              <div class="container">
                <p class="copy-footer-28 text-center"> &copy; 2022 FU TONG E-COMMERCE SERVICES LIMITED. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </footer>

        <!-- madal -->
          <div class="ld-hide">
            <div class="loading-box" id="loading">
               <div class="container2 loading1">
                  <div class="shape shape1"></div>
                  <div class="shape shape2"></div>
                  <div class="shape shape3"></div>
                  <div class="shape shape4"></div>
                </div>
            </div>
          </div>

          <!-- toast -->
          <div class="toast-container position-fixed bottom-0 end-0 p-3">
            <div id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
              <div class="toast-header">
                <!-- <img src="..." class="rounded me-2" alt="..."> -->
                <strong class="me-auto">Bootstrap</strong>
                <small>11 mins ago</small>
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
              </div>
              <div class="toast-body">
                Hello, world! This is a toast message.
              </div>
            </div>
          </div>

          

        <!-- move top -->
        <button @click="topFunction()" id="movetop" title="Go to top">
          &#10548;
        </button>
      </section>
    </div>
</template>
<script>
import "../../public/assets/js/bootstrap.min.js";
import "../../public/assets/js/jquery-3.3.1.min";
import "../../public/assets/js/owl.carousel";
// $(document).ready(function () {
//           $("#Myalert").hide()
//           $("#sendBtn").on("click", () => {
//             $("#Myalert").hide()
//             // $('#loading').modal('show');
//             $(".ld-hide").addClass("ld-show")
//             window.setTimeout(() => {
//               $(".ld-hide").removeClass("ld-show")
//               $("#Myalert").show()
//             }, 3000)
//           })
//         })
import $ from 'jquery'
export default {
    
    mounted() {
    // window.onscroll = function () {
    //     this.scrollFunction()
    // };
    window.addEventListener("scroll", () => {
      this.scrollFunction()
    })
  },
  computed: {
      locales() {
      if (this.$i18n.locale == "tw") {
        return "繁體";
      }else {
        return "English";
      }
    },
  },
  methods: {
      clickSend() {
          console.log('123')
          $("#Myalert").hide()
            $("#Myalert").hide()
            // $('#loading').modal('show');
            $(".ld-hide").addClass("ld-show")
            window.setTimeout(() => {
              $(".ld-hide").removeClass("ld-show")
              $("#Myalert").show()
            }, 3000)
      },
    scrollFunction() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        document.getElementById("movetop").style.display = "block";
      } else {
        document.getElementById("movetop").style.display = "none";
      }
    },
    changeLang() {
      if (this.lang === "tw") {
        this.lang = "en";
        // this.langs = "English"
        this.$i18n.locale = this.lang;
        localStorage.setItem('lang', this.lang);
      } else {
        this.lang = "tw";
        // this.langs = "繁体"
        this.$i18n.locale = this.lang;
        localStorage.setItem('lang', this.lang);
      }
      
    },

    // When the user clicks on the button, scroll to the top of the document
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  }
}
</script>
<style scoped>
@import "../../public/assets/css/style-starter.css";
@import "../../public/assets/css/loading.css";
/* @import "//fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap"; */
/* @import "//fonts.googleapis.com/css?family=Nunito:200,300,400,600,700,800,900&display=swap"; */
</style>